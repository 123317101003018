/* $btnPrimaryColor:#506EEC; */
.mc-checkbox-inp {
  display: none; }

.mc-checkbox-label {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-family: 'Inter'; }

.mc-checkbox-label span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0); }

.mc-checkbox-label span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease; }

.mc-checkbox-label span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0); }

.mc-checkbox-label span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #66CBC9;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%; }

.mc-checkbox-label span:last-child {
  padding-left: 8px; }

.mc-checkbox-label:hover span:first-child {
  border-color: #66CBC9; }

.mc-checkbox-inp:checked + .mc-checkbox-label span:first-child {
  background: #66CBC9;
  border-color: #66CBC9;
  animation: wave 0.4s ease; }

.mc-checkbox-inp:checked + .mc-checkbox-label span:first-child svg {
  stroke-dashoffset: 0; }

.mc-checkbox-inp:checked + .mc-checkbox-label span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease; }

.mc-checkbox.rounded .mc-checkbox-label span:first-child {
  border-radius: 50%; }

@keyframes wave {
  50% {
    transform: scale(0.9); } }
