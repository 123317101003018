.componentloadersmall {
  margin: auto;
  width: 200px;
  height: 50px;
  z-index: 1600;
  /* centralized thanks to Eduardo Lopes suggestion */
  /* -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg); */ }

figure {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 10px;
  display: inline-block;
  background-color: white;
  box-shadow: 0 0 1em rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* transition: all 0.2s ease; */ }

figure:nth-child(1) {
  -webkit-animation: changeColors 1s infinite;
  -moz-animation: changeColors 1s infinite;
  animation: changeColors 1s infinite; }

figure:nth-child(2) {
  -webkit-animation: changeColors 1s infinite .2s;
  -moz-animation: changeColors 1s infinite .2s;
  animation: changeColors 1s infinite .2s; }

figure:nth-child(3) {
  -webkit-animation: changeColors 1s infinite .3s;
  -moz-animation: changeColors 1s infinite .3s;
  animation: changeColors 1s infinite .3s; }

figure:nth-child(4) {
  -webkit-animation: changeColors 1s infinite .4s;
  -moz-animation: changeColors 1s infinite .4s;
  animation: changeColors 1s infinite .4s; }

figure:nth-child(5) {
  -webkit-animation: changeColors 1s infinite .5s;
  -moz-animation: changeColors 1s infinite .5s;
  animation: changeColors 1s infinite .5s; }

@-webkit-keyframes changeColors {
  0%, 49% {
    background-color: #E52900;
    box-shadow: 0 0 1em #e52900; }
  50%, 100% {
    background-color: white;
    box-shadow: 0 0 1em rgba(255, 255, 255, 0.5); } }

@-moz-keyframes changeColors {
  0%, 49% {
    background-color: #E52900;
    box-shadow: 0 0 1em #e52900; }
  50%, 100% {
    background-color: white;
    box-shadow: 0 0 1em rgba(255, 255, 255, 0.5); } }

@keyframes changeColors {
  0%, 49% {
    background-color: #E52900;
    box-shadow: 0 0 1em #e52900; }
  50%, 100% {
    background-color: white;
    box-shadow: 0 0 1em rgba(255, 255, 255, 0.5); } }
