.tutorial_button_primary.shepherd-button {
  color: #f6ffe9;
  background-color: #109468;
  border-color: #109468; }

.tutorial_button_secondary.shepherd-button {
  color: #8e9aab;
  background-color: transparent; }

.shepherd-button {
  background: #109468;
  border: 0;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  margin-right: .5rem;
  padding: .5rem 1.5rem;
  transition: all .5s ease; }

.shepherd-button:not(:disabled):hover {
  background: #109468;
  color: rgba(255, 255, 255, 0.75); }

.shepherd-button.shepherd-button-secondary {
  background: transparent;
  color: 8000000000aab 0, 0, 0, 0.75; }

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background: #d6d9db;
  color: rgba(0, 0, 0, 0.75); }

.shepherd-button:disabled {
  cursor: not-allowed; }

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 .75rem .75rem; }

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0; }

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: rgba(128, 128, 128, 0.75);
  font-size: 2em;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color .5s ease; }

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75); }

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: rgba(128, 128, 128, 0.75); }

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75); }

.shepherd-title {
  font-family: 'Inter';
  background: #232e45;
  color: #fff;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  flex: 1 0 auto;
  margin: 0;
  padding: 0; }

.shepherd-header {
  background: #232e45;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: .75rem .75rem 0; }

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #232e45;
  padding: .5em; }

.shepherd-text {
  font-family: 'Inter';
  color: #c8c9ca;
  font-size: 1rem;
  line-height: 1.3em;
  padding: .75em; }

.shepherd-text p {
  margin-top: 0; }

.shepherd-text p:last-child {
  margin-bottom: 0; }

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
  background-color: #232e45; }

.shepherd-element {
  background: #232e45;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s,visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999; }

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible; }

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden; }

.shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
  box-sizing: border-box; }

.shepherd-arrow, .shepherd-arrow:before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1; }

.shepherd-arrow:before {
  content: "";
  transform: rotate(45deg);
  background: #232e45; }

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px; }

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px; }

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px; }

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px; }

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0; }

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: #232e45; }

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none; }

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
  width: 100vw;
  z-index: 9997; }

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transition: all .3s ease-out,height 0s 0s,opacity .3s 0s; }

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all; }

.shepherd-progress-bar {
  position: absolute;
  left: 2%;
  bottom: 9%;
  font-size: 14px;
  border-radius: 9px;
  height: 20px;
  width: 100%;
  max-width: 180px;
  padding: 3px;
  background: #232e45; }

.shepherd-progress-bar span {
  display: block;
  background: #109468;
  width: 50%;
  height: 100%;
  border-radius: 9px; }

.coloureddot {
  height: 8px;
  width: 8px;
  background-color: #109468;
  border-radius: 50%;
  display: inline-block; }

.dot {
  height: 8px;
  width: 8px;
  background-color: lightgray;
  border-radius: 50%;
  display: inline-block; }

.progress-bar {
  padding: 3px;
  /* position: absolute;
  left: 2%;
  bottom: 9%;
  width: 100%; */
  /* font-size: 14px;
  border-radius: 9px;
  height: 20px;
  width: 100%;
  max-width: 180px;
  padding: 3px;
  background: #232e45; */ }
