.labelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 40px;
  margin-left: 32.5%;
  color: #000;
  background: #E6E6E6;
  text-align: center;
  font-family: Inter;
  font-size: 20;
  border-radius: 5px;
  /* font-weight:bold; */ }

.price-table-box {
  margin-top: 20px; }

.price-page-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.price-page-title .blackTitle {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  text-align: center; }

.price-page-title .redTitle {
  font-size: 35px;
  font-weight: 700;
  color: #c1011a;
  display: block; }

.messageTop {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding-top: 10px; }

fieldset {
  border: 2px solid #c1011a;
  /* padding:30px 30px; */
  max-width: 32%;
  min-width: 32%;
  flex: 1 0 100%;
  border-radius: 20px;
  /* box-sizing: border-box;  */
  /*grid-area: 1 / 1;  first row, first column */
  /* padding: 20px;  */
  /* width: inherit;  */
  margin: 0 auto;
  padding: 1em;
  padding-bottom: 0; }

legend {
  font: 12pt/0 'Inter';
  margin: auto;
  /* to center */
  padding: 1em;
  text-align: center;
  width: 200px;
  color: white;
  background-color: #c1011a;
  border: 2px solid #c1011a;
  border-radius: 8px; }

.text-select {
  color: #bb041a; }

.price-button {
  padding-top: 20px; }

.price-button-subscribe {
  background: #fff;
  font-weight: bold;
  border-radius: 6px;
  color: #c1011a;
  border: 2px solid #c1011a;
  display: block;
  text-align: center;
  padding: 10px;
  text-decoration: none; }

.price-button-currentplan {
  background: #66CBC9;
  font-weight: bold;
  border-radius: 6px;
  color: #fff;
  border: 2px solid #66CBC9;
  display: block;
  text-align: center;
  padding: 10px;
  text-decoration: none; }

.price-button a:hover {
  background: #66CBC9;
  border: 2px solid #66CBC9;
  color: #fff; }

.pricingtable.pro-bg {
  border: 2px solid #c1011a; }

.pricingtable.selectedplan {
  border: 2px solid #66CBC9; }

.enterprise .pricingtable__head {
  font-size: 25px;
  line-height: 1; }

.pricingtablecontainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  /* margin:0 40px; */
  /* padding:0 0 30px 0; */
  justify-content: space-between; }

.pricingtable {
  padding: 30px 30px;
  max-width: 30%;
  min-width: 30%;
  flex: 1 0 100%;
  border-radius: 20px;
  border: 2px solid #e3e3e3;
  margin: 15px; }

.pricingtable .js-yearlypricing {
  display: none; }

.pricingtable ul {
  position: relative;
  padding: 10px 0 0 0;
  margin-top: 15px;
  margin-bottom: 0; }

.pricingtable li {
  border-bottom: 0 solid #dbdbdb;
  padding: 8px 10px 8px 35px;
  list-style: none;
  line-height: 1.2;
  align-items: center;
  background: url(https://www.intelisa.in/wp-content/themes/twentytwenty/images/tick.svg) no-repeat 0 center;
  background-size: 26px; }

fieldset li {
  border-bottom: 0 solid #dbdbdb;
  padding: 8px 10px 8px 35px;
  list-style: none;
  line-height: 1.2;
  align-items: center;
  background: url(https://www.intelisa.in/wp-content/themes/twentytwenty/images/tick.svg) no-repeat 0 center;
  background-size: 26px; }

fieldset li span {
  font-weight: bold;
  color: #c1011a; }

.pricingtable li span
fieldset li span {
  font-weight: bold;
  color: #c1011a; }

.pricingtable__head {
  border: none !important;
  font-weight: bold;
  min-height: 45px; }

.pricingtable__highlight {
  color: 000;
  min-height: 80px; }

.pricingtable__popular {
  background: #f0c80f;
  border: none !important;
  border-radius: 5px 5px 0 0;
  color: #fff;
  padding: 5px !important;
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  text-transform: uppercase; }

.pricingtable__highlight span.prices {
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
  margin-right: 10px; }

.pricingtable__highlight {
  display: flex;
  align-items: center; }

.slideToggle {
  display: flex;
  justify-content: center;
  margin: 25px 0; }

.slideToggle i {
  margin: 0 15px; }

.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  padding: 0; }

.form-switch i {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  border: 0 solid #dfdfdf;
  border-radius: 15px;
  transition: all .3s linear;
  background: #bb0418; }

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 15px;
  transform: translate3d(4px, 3px, 0);
  transition: all .2s ease-in-out; }

.form-switch input {
  display: none; }

.form-switch input:checked + i::after {
  transform: translate3d(26px, 2px, 0); }

@media (max-width: 991px) {
  .pricingtable {
    padding: 30px 20px;
    width: 100%;
    min-width: 100%;
    flex: inherit;
    margin-bottom: 30px;
    border: 2px solid #e3e3e3; }
  .pricingtablecontainer {
    display: block;
    flex-wrap: nowrap;
    overflow: auto;
    margin: 0 0;
    padding: 0 0 30px 0; }
  .pricingtable__head {
    min-height: inherit; }
  .pricingtable__highlight {
    min-height: inherit; } }

@media (max-width: 580px) {
  .pricingtable {
    padding: 30px 10px; } }

.container-fluid1 {
  margin-top: 35px; }

.homeslider-active .owl-pagination {
  width: 30px;
  left: inherit;
  right: 5px;
  bottom: 55%;
  position: absolute;
  z-index: 500;
  text-align: center; }

.homeslider-active .owl-pagination .owl-page {
  width: 12px;
  height: 12px;
  margin: 3px 6px;
  background: #a0dede;
  display: inline-block;
  text-align: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.homeslider-active .owl-pagination .owl-page.active {
  background: #ac252b; }

body {
  margin: 0;
  padding: 0; }

img.bg-pc {
  display: block; }

img.bg-mo {
  display: none !important; }

.rm-menu .nav > li {
  position: static !important; }

.core-menu {
  text-align: center;
  font: 1em/1 Roboto,sans-serif;
  overflow: hidden; }

.core-menu li {
  list-style: none;
  display: inline-block;
  cursor: pointer; }

.core-menu li a {
  display: inline-block;
  padding: 1em; }

.core-menu li a:hover {
  background: #38a201; }

li span.toggle {
  padding-left: 1em; }

.toggle:before {
  content: "\F107";
  font: 16px/1 FontAwesome; }

.dropdown {
  position: absolute;
  width: 230px;
  background: #000;
  text-align: left;
  display: none;
  padding-top: 17px; }

.dropdown li {
  display: block;
  border-top: 1px solid #1a1a1a; }

.dropdown li a {
  display: block;
  padding: 5px 5px !important; }

.dropdown2 {
  position: absolute;
  width: 230px;
  background: #2d2d2d;
  text-align: left;
  display: none;
  right: -100%;
  top: 0; }

.dropdown2 li {
  display: block; }

.dropdown2 li a {
  display: block; }

li span.toggle2 {
  position: absolute;
  right: 0;
  margin-right: 20px; }

.toggle2:before {
  content: "\F105";
  font: 16px/1 FontAwesome; }

.hamburger-menu {
  padding: 1em;
  display: none;
  font: 16px/1 Roboto;
  text-transform: uppercase; }

.shrink .dropdown {
  background: #fff; }

.shrink .dropdown li {
  display: block;
  border-top: 1px solid #ccc; }

@media (max-width: 768px) {
  .navbar-header {
    float: left; }
  .core-menu {
    float: right;
    width: 100%;
    margin: auto;
    display: none; }
  .core-menu li {
    width: 100%;
    display: block; }
  .core-menu li a {
    display: block;
    margin: auto; }
  .hamburger-menu {
    display: block;
    text-align: center;
    float: right;
    height: 35px;
    padding: 0;
    margin: 0;
    width: 30px;
    position: relative;
    cursor: pointer; }
  .burger-1, .burger-2, .burger-3 {
    display: block;
    position: absolute;
    width: 20px;
    height: 3px;
    border-radius: 30px;
    right: 0;
    margin-right: 0;
    background: #fff;
    -webkit-transition: .2s ease;
    -ms-transition: .2s ease;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease; }
  .navbar.shrink .burger-1, .navbar.shrink .burger-2, .navbar.shrink .burger-3 {
    background: #000; }
  .burger-1 {
    top: 15px; }
  .burger-2 {
    top: 23px; }
  .burger-3 {
    top: 31px; }
  .burger-1.open, .burger-2.open, .burger-3.open {
    -webkit-transition: .2s ease;
    -ms-transition: .2s ease;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    transform-origin: 50% 50%; }
  .burger-1.open {
    top: 25px;
    width: 23px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg); }
  .burger-2.open {
    opacity: 0; }
  .burger-3.open {
    top: 25px;
    width: 23px;
    transform: rotate(-45deg); }
  .dropdown {
    width: 100%;
    text-align: center;
    position: relative;
    padding-top: 5px; }
  .shrink .dropdown li {
    border-top: 0 solid #1a1a1a; }
  .dropdown li {
    display: block;
    border-top: 0 solid #1a1a1a; }
  .dropdown2 {
    right: 0;
    top: 0;
    width: 100%;
    position: relative;
    padding: 0 20px; } }

.owl-wrapper .owl-item-inner {
  position: relative; }

.banner-txt {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;
  bottom: 10%;
  color: #fff;
  text-align: center;
  padding: 30px 0; }

.banner-txt h2 {
  font-weight: normal;
  font-size: 36px;
  color: #fff; }

.banner-txt h2 span {
  color: #b3272d; }

.btn-box-banner {
  padding: 15px 0 0 0; }

.btn-box-banner a.first-btn {
  background: #fff;
  color: #202125;
  text-align: center;
  border: none;
  width: 160px;
  padding: 10px 0;
  margin: 0 0 0 0; }

.btn-box-banner a.second-btn {
  background: transparent;
  color: #fff;
  text-align: center;
  margin: 0 0 0 0;
  border: 1px solid #fff;
  width: 160px; }

.section2 {
  padding: 88px 0 0 0; }

.homeslider-active .owl-prev {
  display: inline-block;
  font-size: 40px;
  position: absolute;
  z-index: 50;
  top: 40%;
  background: #9edbdbcc;
  line-height: 45px; }

.homeslider-active .owl-next {
  display: inline-block;
  font-size: 40px;
  position: absolute;
  z-index: 50;
  top: 40%;
  background: #9edbdbcc;
  line-height: 45px; }

.homeslider-active .owl-buttons {
  display: none; }

.homeslider-active .owl-next:hover {
  background: #9edbdb; }

.homeslider-active .owl-prev:hover {
  background: #9edbdb; }

.homeslider-active .owl-prev {
  left: 0;
  border-radius: 0 15px 15px 0;
  padding: 0 10px 5px 5px; }

.homeslider-active .owl-next {
  right: 0;
  border-radius: 15px 0 0 15px;
  padding: 0 5px 5px 10px; }

.homeslider-active .owl-dots {
  top: 45%;
  margin: -50px 0 0 0;
  position: absolute;
  right: 30px;
  text-align: center;
  z-index: 99; }

.homeslider-active .owl-dot {
  border-radius: 6px;
  display: block;
  height: 20px;
  margin: 4px 2px;
  width: 12px;
  border: 0 solid #000;
  background: #9860d6; }

.homeslider-active .owl-dot.active {
  position: relative;
  height: 40px;
  background: -moz-linear-gradient(339deg, #47b766 0%, #2cacd0 46%, #70669d 93%, #70669d 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #47b766), color-stop(46%, #2cacd0), color-stop(93%, #70669d), color-stop(100%, #70669d));
  background: -webkit-linear-gradient(339deg, #47b766 0%, #2cacd0 46%, #70669d 93%, #70669d 100%);
  background: -o-linear-gradient(339deg, #47b766 0%, #2cacd0 46%, #70669d 93%, #70669d 100%);
  background: -ms-linear-gradient(339deg, #47b766 0%, #2cacd0 46%, #70669d 93%, #70669d 100%);
  background: linear-gradient(111deg, #47b766 0%, #2cacd0 46%, #70669d 93%, #70669d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47b766', endColorstr='#70669d',GradientType=1 ); }

.screenshots2 .homeslider-active .owl-dot {
  border: 2px solid #000; }

.screenshots2 .homeslider-active .owl-dot.active {
  background: #b7b1ce;
  position: relative; }

.homeslider-active.owl-carousel .owl-item img {
  display: inline-block;
  width: 100%; }

@media (max-width: 1024px) {
  .owl-pagination {
    width: 100%;
    left: 0;
    bottom: -20px;
    position: absolute;
    z-index: 800;
    text-align: center; }
  .banner-txt {
    bottom: 5%;
    color: #fff;
    text-align: center;
    padding: 10px 0; }
  .banner-txt h2 {
    font-weight: normal;
    font-size: 25px;
    color: #fff; }
  .banner-txt h2 span {
    color: #b3272d; }
  .btn-box-banner {
    padding: 15px 0 0 0; }
  .btn-box-banner a.first-btn {
    background: #fff;
    color: #202125;
    text-align: center;
    border: none;
    width: 160px;
    padding: 10px 0;
    margin: 0 0 0 0; }
  .btn-box-banner a.second-btn {
    background: transparent;
    color: #fff;
    text-align: center;
    margin: 0 0 0 0;
    border: 1px solid #fff;
    width: 160px; } }

@media (max-width: 680px) {
  .homeslider-active .owl-pagination {
    width: 30px;
    left: inherit;
    right: 5px;
    bottom: 55%;
    position: absolute;
    z-index: 500;
    text-align: center; }
  .homeslider-active .owl-pagination .owl-page {
    width: 12px;
    height: 12px;
    margin: 3px 6px;
    background: #a0dede;
    display: inline-block;
    text-align: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  img.bg-pc {
    display: none !important; }
  img.bg-mo {
    display: block !important; } }
